angular.module("app.config", [])
.constant("useServerMock", false)
.constant("useWebSockets", true)
.constant("useRemoteServerConnection", true)
.constant("hostProtocol", "https")
.constant("webSocketProtocol", "wss")
.constant("hostDomain", "stage.ready2wash.net")
.constant("useMqttBroker", true)
.constant("mqttBrokerIp", "stage.ready2wash.net")
.constant("mqttBrokerPort", 9002)
.constant("useMqttBrokerSsl", true)
.constant("brand", "r2w")
.constant("service", true);
